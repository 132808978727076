<template>
<div :key="`key_${title}_${key}`">
    <CourseSubHeader
        :show-marking-period-picker="true"
        :increment-key="incrementKey"
    >
        <template #buttons>
            <button
                class="btn btn-bold btn-sm"
                :class="{
                    'btn-primary': showTeacherComments,
                    'btn-clean btn-clean-primary': !showTeacherComments,
                }"
                @click.stop.prevent="showTeacherComments = !showTeacherComments"
            >
                {{ showTeacherComments ? 'Hide' : 'Show' }} Comments
            </button>
        </template>
    </CourseSubHeader>

    <b-overlay
        :variant="'transparent'"
        no-center
        opacity="1"
        blur="10px"
        :show="encryptionEnabled"
    >
        <template #overlay>
            <div class="text-center pt-4 kt-font-lg">
                PII is currently encrypted. Please unlock PII to view this page.
            </div>
            <div class="text-center pt-4">
                <button
                    v-if="encryptionEnabled"
                    type="button"
                    style="min-width: 160px;"
                    class="btn btn-label-brand btn-bold btn-upper mt-4"
                    @click="decrypt()"
                >
                    Unlock PII
                </button>
            </div>
        </template>
        <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div class="col-lg-6">
                    <div class="kt-portlet">
                        <div class="kt-portlet__body p-3">
                            <div class="kt-widget kt-widget--user-profile-3 pb-4">
                                <div class="kt-widget__top">
                                    <div class="kt-widget__content pl-3">
                                        <div class="kt-widget__head">
                                            <h3
                                                class="kt-widget__title"
                                                style="cursor: default;"
                                            >
                                                Create Progress Report
                                            </h3>
                                            <div class="kt-widget__action">
                                                <div class="row">
                                                    <div class="nav-item">
                                                        <span
                                                            class="kt-link kt-font-bold nav-link"
                                                            :style="progressReportParams.reportType === 'Course Level' ? '' : 'color: gray;'"
                                                            :class="{ 'kt-font-primary kt-font-boldest': progressReportParams.reportType === 'Course Level'}"
                                                            @click.stop.prevent="progressReportParams.reportType = 'Course Level'"
                                                        >

                                                            Course Level
                                                        </span>
                                                    </div>
                                                    <div class="nav-item">
                                                        <span
                                                            class="kt-link kt-link--state kt-link--success kt-font-bold nav-link"
                                                            :style="progressReportParams.reportType === 'School Level' ? '' : 'color: gray;'"
                                                            :class="{ 'kt-font-success kt-font-boldest': progressReportParams.reportType === 'School Level'}"
                                                            @click.stop.prevent="progressReportParams.reportType = 'School Level'"
                                                        >

                                                            School Level
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-portlet__content">
                                <div class="kt-section pl-4 pr-4">
                                    <div class="kt-section__desc text-muted">
                                        Configure what to include in Progress Report
                                    </div>
                                    <div class="kt-section__content">
                                        <div class="row">
                                            <div
                                                v-if="progressReportParams.reportType === 'Course Level'"
                                                class="col-4"
                                            >
                                                <div class="kt-checkbox-list">
                                                    <label
                                                        v-b-tooltip.hover="'Lists each assignment, completion status, grade and scaled score'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showAssignments"
                                                            type="checkbox"
                                                        > Assignments
                                                        <span />
                                                    </label>
                                                    <label
                                                        v-b-tooltip.hover="'Includes the most recent non-present course attendance records'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showAttendance"
                                                            type="checkbox"
                                                        > Attendance
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                v-if="progressReportParams.reportType === 'Course Level'"
                                                class="col-4"
                                            >
                                                <div class="kt-checkbox-list">
                                                    <label
                                                        v-b-tooltip.hover="'Include section with average for each course'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showCourseAverage"
                                                            type="checkbox"
                                                        > Course Average
                                                        <span />
                                                    </label>
                                                    <label
                                                        v-b-tooltip.hover="'Include table of grading categories with category averages and scaled scores'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showGradeBreakdown"
                                                            type="checkbox"
                                                        > Grade Breakdown
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="kt-checkbox-list">
                                                    <label
                                                        v-b-tooltip.hover="'Includes teacher defined comments for course'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showComments"
                                                            type="checkbox"
                                                        > Comments
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div class="kt-checkbox-list">
                                                <label
                                                    v-b-tooltip.hover="'Include section with average for each course'"
                                                    class="kt-checkbox"
                                                >
                                                    <input
                                                        v-model="progressReportParams.showAnnouncement"
                                                        type="checkbox"
                                                    > School Announcement
                                                    <span />
                                                </label>
                                                <label
                                                    v-b-tooltip.hover="'Students can view report on portfolio'"
                                                    class="kt-checkbox kt-checkbox--bold kt-checkbox--success"
                                                >
                                                    <input
                                                        v-model="progressReportParams.showOnPortfolio"
                                                        type="checkbox"
                                                    > Visible to Students
                                                    <span />
                                                </label>
                                                <label
                                                    v-b-tooltip.hover="'Notifies students/guardians a progress report was made'"
                                                    class="kt-checkbox kt-checkbox--bold kt-checkbox--success"
                                                >
                                                    <input
                                                        v-model="progressReportParams.notifyToEmail"
                                                        type="checkbox"
                                                    > Notify to Email
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <b-collapse
                                            v-model="progressReportParams.showAnnouncement"
                                            class="kt-menu__submenu pt-3"
                                        >
                                            <div>
                                                Progress Report Announcement
                                            </div>
                                            <textarea
                                                v-model="progressReportParams.announcement"
                                                class="form-control"
                                                rows="3"
                                            />
                                        </b-collapse>
                                    </div>
                                </div>
                                <div class="pull-right">
                                    <button
                                        type="button"
                                        class="btn btn-warning btn-sm btn-bold btn-upper"
                                        @click.stop.prevent="confirmGenerateProgressReport"
                                    >
                                        <i class="la la-archive" />
                                        Generate
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="kt-portlet">
                        <div class="kt-portlet__body p-3">
                            <div
                                v-if="reportCard"
                                class="kt-widget kt-widget--project-1"
                                style="cursor: pointer;"
                                @click.stop.prevent="openReport(reportCard)"
                            >
                                <div class="kt-widget__head d-flex">
                                    <div class="kt-widget__label">
                                        <div class="kt-widget__info kt-padding-0 kt-margin-l-15">
                                            <a
                                                href="#"
                                                class="kt-widget__title"
                                                @click.stop.prevent="openReport(reportCard)"
                                            >
                                                Report Card
                                            </a>
                                            <span class="kt-widget__desc">
                                                <span class="kt-badge kt-badge--unified-primary kt-badge--inline">
                                                    Report Cards Released
                                                </span>
                                                <div class="mt-4">
                                                    On <code>{{ reportCard.created }}</code>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="kt-widget__toolbar">
                                        <button
                                            class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                            @click.stop.prevent="openReport(reportCard)"
                                        >
                                            <i class="flaticon-download" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="kt-widget kt-widget--user-profile-3 pb-4"
                            >
                                <div class="kt-widget__top">
                                    <div class="kt-widget__content pl-3">
                                        <div class="kt-widget__head">
                                            <h3
                                                class="kt-widget__title"
                                                style="cursor: default;"
                                            >
                                                Report Card
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="reportCard"
                                class="kt-portlet__content"
                            >
                                <div class="pull-right">
                                    Released By {{ reportCard.author }}
                                </div>
                            </div>
                            <div
                                v-else
                                class="kt-portlet__content"
                            >
                                <div class="kt-section">
                                    <div class="kt-section__content">
                                        <div class="alert alert-solid-warning alert-bold">
                                            <div class="alert-icon">
                                                <i class="flaticon-warning" />
                                            </div>
                                            <div class="alert-text">
                                                Report Cards have not been released/configured by administrator
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-collapse
            v-model="showTeacherComments"
        >
            <ProgressReportComments
                :key="`progress-report-comments-${key}`"
                :school-staff-id="schoolStaffId"
            />
        </b-collapse>

        <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="row">
                <div
                    v-for="(report, idx) in filteredReports"
                    :key="`report_${report.reportId}_${idx}`"
                    class="col-lg-4"
                >
                    <div
                        class="kt-portlet kt-portlet--solid kt-portletk-portlet--height-fluid"
                        style="cursor: pointer;"
                        @click.stop.prevent="openProgressReport(report)"
                    >
                        <div class="kt-portlet__head kt-portlet__head--noborder">
                            <div class="kt-portlet__head-label">
                                <a
                                    href="#"
                                    class="kt-portlet__head-title"
                                    :class="{
                                        'kt-link kt-link--state kt-link--primary': report.reportParams.reportType === 'Course Level',
                                        'kt-link kt-link--state kt-link--success': report.reportParams.reportType === 'School Level',
                                    }"
                                    @click.stop.prevent="openProgressReport(report)"
                                >
                                    {{ report.reportType }}
                                </a>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                <div v-if="report.generatedRole === 'School Admin'">
                                    <span
                                        class="kt-badge kt-badge--warning kt-badge--inline mr-2"
                                        style="cursor: default;"
                                    >Admin</span>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__body pt-0 mt-0">
                            <div>
                                <div class="kt-font-bold">
                                    Generated on <br><code>{{ report.created }}</code>
                                </div>

                                <div class="mt-3">
                                    <span
                                        class="kt-badge kt-badge--bold kt-badge--inline"
                                        :class="{
                                            'kt-badge--success': report.reportParams.reportType === 'School Level',
                                            'kt-badge--primary': report.reportParams.reportType === 'Course Level',
                                        }"
                                    >
                                        {{ report.reportParams.reportType }}
                                    </span>

                                    <span
                                        class="kt-badge kt-badge--bold kt-badge--inline ml-3"
                                        :class="{
                                            'kt-badge--unified-primary': report.showOnPortfolio,
                                            'kt-badge--unified-warning': !report.showOnPortfolio,
                                        }"
                                    >
                                        {{ report.showOnPortfolio ? 'Visible' : 'Hidden' }}
                                    </span>
                                </div>

                                <div class="pull-right">
                                    By {{ report.author }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>

    <ConfirmModal
        ref="confirmModal"
        :confirm-modal-visible="confirmModalVisible"
        :cancel-function="cancelConfirm"
        :confirm-function="confirmFunction"
        :processing="generating"
        :header="confirmHeader"
        :message="confirmMessage"
        :color="confirmColor"
    />

    <ReportSearchModal
        ref="reportSearchModal"
        :report-search-visible="reportSearchVisible"
        :close-report-search="closeReportSearch"
        :remove-report="removeReport"
        :report="selectedReport"
        :open-report-pdf="openReportPdf"
        :courses="courses"
        :home-rooms="homeRooms"
        :grade-levels="gradeLevels"
    />

    <PDFViewModal ref="pdfViewModal" />
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import teacherMixins from '../store/mixins/teacherMixins';
import CourseSubHeader from './CourseSubHeader.vue';
import ProgressReportComments from '../components/ProgressReports/ProgressReportComments.vue';
import PDFViewModal from '../components/PDFViewModal.vue';
import ConfirmModal from '../components/ConfirmModal.vue';
import ReportSearchModal from '../components/ReportSearchModal.vue';
import reportCardMixins from '../store/mixins/reportCardMixins';
import { getStudentGradeLevels, getStudentHomeRooms } from '../store/mixins/studentMixins';
import { getCourses, CourseFilter } from '../store/mixins/courseMixins';
import * as network from '../network';
import Types from '../store/Types';

export default {
    name: 'CourseReports',
    components: {
        CourseSubHeader,
        ProgressReportComments,
        PDFViewModal,
        ConfirmModal,
        ReportSearchModal,
    },
    mixins: [teacherMixins, reportCardMixins],
    data() {
        return {
            key: 0,
            reports: [],
            showTeacherComments: false,
            generating: false,
            selectedReport: null,
            reportSearchVisible: false,
            confirmHeader: '',
            confirmMessage: '',
            confirmColor: 'Blue',
            confirmFunction: () => {},
            confirmModalVisible: false,
            progressReportParams: {
                generatedRole: 'Teacher',
                reportType: 'School Level',
                showAssignments: true,
                showAttendance: true,
                showCourseAverage: true,
                showGradeBreakdown: true,
                showComments: true,
                showAnnouncement: false,
                announcement: '',
                showOnPortfolio: false,
                notifyToEmail: false,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
        }),
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        schoolStaffId() {
            const { teacher } = this;
            return teacher.schoolStaffId;
        },
        title() {
            return this.$route.name;
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        markingPeriods() {
            return this.$_reportCardMixins_getMarkingPeriods.filter((mp) => !mp.deleted);
        },
        markingPeriod() {
            const { markingPeriods, schoolTermMarkingPeriodId } = this;
            return markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
        },
        reportCard() {
            const { reports, schoolTermMarkingPeriodId } = this;
            if (!reports || !reports.length) return null;
            const reportCard = reports.find((r) => r.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId && r.reportType === 'Report Card') || null;
            if (!reportCard) return null;
            return {
                ...reportCard,
                created: moment(reportCard.created).utc().format('MM/DD/YYYY hh:mm A'),
            };
        },
        courses() {
            const { schoolStaffId } = this;
            const { database } = this.$store.state;
            return getCourses(database, new CourseFilter({ schoolStaffId }));
        },
        homeRooms() {
            const { database } = this.$store.state;
            return getStudentHomeRooms(database);
        },
        gradeLevels() {
            const { database } = this.$store.state;
            return getStudentGradeLevels(database);
        },
        filteredReports() {
            const { reports, schoolTermMarkingPeriodId } = this;
            return reports.filter((r) => r.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId && r.reportType === 'Progress Report').map((r) => ({
                ...r,
                created: moment(r.created).utc().format('YYYY-MM-DD HH:mm A'),
            })).sort((a, b) => moment(b.created).diff(a.created));
        },
    },
    watch: {
        extCourseSectionId() {
            this.key += 1;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        incrementKey() {
            this.key += 1;
        },
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        populate() {
            const v = this;
            const { school, schoolStaffId } = v;
            const { schoolId, schoolTermId } = school;
            network.reports.getReportsForStaff({ url: { schoolId, schoolTermId, schoolStaffId } }, (err, results) => {
                if (err) return v.showError('Unable to get reports');
                v.reports = results.reports || [];
            });
        },
        cancelConfirm() {
            this.confirmModalVisible = false;
        },
        openProgressReport(report) {
            this.selectedReport = report;
            this.reportSearchVisible = true;
        },
        closeReportSearch() {
            this.selectedReport = null;
            this.reportSearchVisible = false;
        },
        removeReport(reportId) {
            this.reports = this.reports.filter((r) => r.reportId !== reportId);
        },
        confirmGenerateProgressReport() {
            const now = moment().format('MM/DD/YYYY hh:mm A');
            const { progressReportParams } = this;
            this.confirmHeader = `${progressReportParams.reportType}`;
            this.confirmMessage = `Generating Progress Report for school for ${now}. Reports are only made for students with enough data to generate a report.`;
            this.confirmColor = 'Green';
            this.confirmFunction = this.generateProgressReport;
            this.confirmModalVisible = true;
        },
        generateProgressReport() {
            const v = this;
            if (v.generating) return;
            v.generating = true;

            const { school, progressReportParams, schoolStaffId } = v;
            const { schoolId, schoolTermId } = school;
            const { schoolTermMarkingPeriodId } = v.$store.state.settings;
            const { schoolTermEncryptionKey } = this.$store.state.database;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolStaffId,
                },
                body: {
                    schoolTermEncryptionKey,
                    schoolTermMarkingPeriodId,
                    progressReportParams,
                },
            };

            network.progressReports.createProgressReport(params, (err, res) => {
                v.generating = false;
                if (err) return v.showError(err);
                v.confirmModalVisible = false;
                v.populate();
            });
        },
        openReport(report) {
            this.selectedReport = report;
            this.reportSearchVisible = true;
        },
        openReportPdf(url) {
            this.$refs.pdfViewModal.open(url);
        },
    },
};
</script>
