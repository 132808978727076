var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _c("form", { staticClass: "kt-form kt-form--label-right" }, [
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 kt-margin-b-20-tablet-and-mobile" },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "kt-form__control" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _vm._m(0),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filter,
                                expression: "filter",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: `Filter...` },
                            domProps: { value: _vm.filter },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.filter = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3 kt-margin-b-20-tablet-and-mobile" },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.studentCategory,
                              expression: "studentCategory",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.studentCategory = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", [_vm._v(" All Students ")]),
                          _c("option", [_vm._v(" Mising Comments ")]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _vm._l(_vm.filteredStudents, function (student, idx) {
                    return [
                      _c(
                        "div",
                        {
                          key: `student_${student.studentId}_${idx}`,
                          staticClass: "pt-3 pb-3",
                        },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(student.lastName) +
                                ", " +
                                _vm._s(student.firstName)
                            ),
                          ]),
                          _c("div", { staticClass: "input-group" }, [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("span", { staticClass: "input-group-text" }, [
                                _c("i", {
                                  staticClass: "la la-comment kt-font-success",
                                  class: {
                                    "kt-font-success": !_vm.saving.includes(
                                      student.studentEnrollmentId
                                    ),
                                    "kt-font-warning": _vm.saving.includes(
                                      student.studentEnrollmentId
                                    ),
                                  },
                                }),
                              ]),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: student.comment.comment,
                                  expression: "student.comment.comment",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                disabled: !_vm.canEditComments,
                                placeholder: `Student Comment...`,
                              },
                              domProps: { value: student.comment.comment },
                              on: {
                                keyup: function ($event) {
                                  return _vm.debounceSaveComment(
                                    student.comment
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    student.comment,
                                    "comment",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  class: {
                                    "btn-success": _vm.saved.includes(
                                      student.studentEnrollmentId
                                    ),
                                  },
                                  attrs: {
                                    type: "button",
                                    disabled: !_vm.canEditComments,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.debounceSaveComment(
                                        student.comment
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.saving.includes(
                                    student.studentEnrollmentId
                                  )
                                    ? _c("i", {
                                        staticClass:
                                          "kt-spinner kt-spinner--light pl-3 pr-3",
                                      })
                                    : _vm.saved.includes(
                                        student.studentEnrollmentId
                                      )
                                    ? _c("i", {
                                        staticClass: "fa fa-check ml-2",
                                        staticStyle: { color: "white" },
                                      })
                                    : _c("span", [_vm._v(" Save ")]),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la la-search" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }