<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <form class="kt-form kt-form--label-right">
                <div class="kt-portlet__body">
                    <div class="row align-items-center">
                        <div class="col-md-6 kt-margin-b-20-tablet-and-mobile">
                            <div class="form-group">
                                <div class="kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="la la-search" /></span>
                                        </div>
                                        <input
                                            v-model="filter"
                                            type="text"
                                            :placeholder="`Filter...`"
                                            class="form-control"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 kt-margin-b-20-tablet-and-mobile">
                            <div class="form-group">
                                <select
                                    v-model="studentCategory"
                                    class="form-control"
                                >
                                    <option>
                                        All Students
                                    </option>
                                    <option>
                                        Mising Comments
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <template v-for="(student, idx) in filteredStudents">
                            <div
                                :key="`student_${student.studentId}_${idx}`"
                                class="pt-3 pb-3"
                            >
                                <label>{{ student.lastName }}, {{ student.firstName }}</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i
                                                :class="{
                                                    'kt-font-success': !saving.includes(student.studentEnrollmentId),
                                                    'kt-font-warning': saving.includes(student.studentEnrollmentId)
                                                }"
                                                class="la la-comment kt-font-success"
                                            />
                                        </span>
                                    </div>
                                    <input
                                        v-model="student.comment.comment"
                                        type="text"
                                        class="form-control"
                                        :disabled="!canEditComments"
                                        :placeholder="`Student Comment...`"
                                        @keyup="debounceSaveComment(student.comment)"
                                    >
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-primary"
                                            :class="{
                                                'btn-success': saved.includes(student.studentEnrollmentId),
                                            }"
                                            type="button"
                                            :disabled="!canEditComments"
                                            @click="debounceSaveComment(student.comment)"
                                        >
                                            <i
                                                v-if="saving.includes(student.studentEnrollmentId)"
                                                class="kt-spinner kt-spinner--light pl-3 pr-3"
                                            />
                                            <i
                                                v-else-if="saved.includes(student.studentEnrollmentId)"
                                                class="fa fa-check ml-2"
                                                style="color: white;"
                                            />
                                            <span v-else>
                                                Save
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import * as network from '../../network';
import courseMixins from '../../store/mixins/courseMixins';
import studentMixins from '../../store/mixins/studentMixins';

export default {
    name: 'ProgressReportComments',
    components: {
    },
    mixins: [courseMixins, studentMixins],
    props: {
        schoolStaffId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            key: 0,
            debounce: null,
            debounceSave: null,
            filter: null,
            studentCategory: 'All Students',
            comments: [],
            saving: [],
            saved: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        students() {
            const { course } = this;
            return this.$_studentMixins_getStudentsForCourse(course);
        },
        filteredStudents() {
            const {
                students, filter, comments, studentCategory, schoolTermMarkingPeriodId, course,
            } = this;
            const { courseSectionId } = course;
            return students.map((student) => {
                const s = student;
                s.comment = comments.find((c) => c.studentEnrollmentId == student.studentEnrollmentId && c.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId)
                    || {
                        studentEnrollmentId: student.studentEnrollmentId,
                        schoolTermMarkingPeriodId,
                        courseSectionId,
                        comment: null,
                    };
                return s;
            }).filter((student) => {
                if (studentCategory === 'Mising Comments') {
                    if (filter) {
                        return (!student.comment || !student.comment.comment) && `${student.lastName.toLowerCase()}, ${student.firstName.toLowerCase()}`.includes(filter.toLowerCase());
                    }
                    return !student.comment || !student.comment.comment;
                }
                if (filter) {
                    return `${student.lastName.toLowerCase()}, ${student.firstName.toLowerCase()}`.includes(filter.toLowerCase());
                }
                return true;
            });
        },
        canEditComments() {
            const { course, user } = this;
            const { teachers, canEditCourse } = course;
            return canEditCourse && teachers.length > 0 && Boolean(teachers.find((t) => t.schoolStaffId == user.school.schoolStaffId));
        },
    },
    mounted() {
        this.debouncePopulate();
    },
    methods: {
        debouncePopulate() {
            const v = this;
            clearTimeout(v.debounce);
            v.debounce = setTimeout(() => {
                if (v) {
                    v.populate();
                }
            }, 10);
        },
        populate() {
            const v = this;
            const { schoolTermId, schoolId } = this.user.school;
            const { schoolStaffId } = this;
            network.progressReports.getCommentsForStaff({ url: { schoolTermId, schoolId, schoolStaffId } }, (err, result) => {
                if (err) return v.showError('There was an error retrieving student comments');
                v.comments = result.comments;
            });
        },
        debounceSaveComment(comment) {
            const v = this;
            clearTimeout(v.debounceSave);
            v.dirty = true;
            v.debounceSave = setTimeout(() => {
                if (v) {
                    v.saveComment(comment);
                }
            }, 250);
        },
        saveComment(comment) {
            const v = this;
            if (v.saving.includes(comment.studentEnrollmentId)) return;
            v.saving.push(comment.studentEnrollmentId);
            const { schoolTermId, schoolId } = this.user.school;
            const { schoolStaffId } = this;
            const params = {
                url: { schoolTermId, schoolId, schoolStaffId },
                body: { comments: [comment] },
            };
            network.progressReports.saveStudentComments(params, (err, results) => {
                v.saving = v.saving.filter((id) => id != comment.studentEnrollmentId);
                if (err) return v.showError('There was a problem saving this comment');
                v.saved.push(comment.studentEnrollmentId);
                setTimeout(() => {
                    v.saved = v.saved.filter((id) => id != comment.studentEnrollmentId);
                }, 750);
            });
        },
    },
};
</script>
